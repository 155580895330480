<template>
  <div class="container">
    <ApolloQuery
      :query="require('src/graphql/CaAdmin.gql')"
      class="row"
    >
      <template slot-scope="{ result: { data } }">
        <div class="col-lg-3 col-md-6">
          <stats-card
            :title="(data!=null) ? Math.floor(data.statsCaJG_Lead[0].ca + data.statsCaJG_Click[0].ca + ((getCaAndLeads) ? getCaAndLeads[getCaAndLeads.length-1].ca : 0) ).toLocaleString() + ' €' : ''"
            :loading='data==null'
            :sub-title="$t('Pages.dashboard.CaGlobalDay')"
            type="primary"
            icon="tim-icons icon-coins"
          >
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6">
          <stats-card
            :title="(data!=null) ? data.statsCaJG_Lead[0].marge.toFixed(1) + ' %' : ''"
            :loading='data==null'
            :sub-title="$t('Pages.dashboard.MarginDay')"
            type="primary"
            icon="tim-icons icon-chart-bar-32"
          >
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6">
          <stats-card
            :title="(data!=null) ? Math.floor(data.statsCaMG_Lead[0].ca + data.statsCaMG_Click[0].ca  + ((getCaOfMonth) ? getCaOfMonth.ca : 0) ).toLocaleString() + ' €' : ''"
            :loading='data==null'
            :sub-title="$t('Pages.dashboard.CaGlobalMonth')"
            type="primary"
            icon="tim-icons icon-coins"
          >
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6">
          <stats-card
            :title="(data!=null) ? data.statsCaMG_Lead[0].marge.toFixed(1) + ' %' : ''"
            :loading='data==null'
            :sub-title="$t('Pages.dashboard.MarginMonth')"
            type="primary"
            icon="tim-icons icon-chart-bar-32"
          >
          </stats-card>
        </div>
      </template>
    </ApolloQuery>

    <!-- Small charts -->
    <div class="row">
      <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <div class="card card-chart" data="blue">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3 text-left">
                <h5 class="card-category" style="color:#fff">WEEDO</h5>
                <h2 class="card-title">PERF</h2>
              </div>
              <div class="col-sm-3 text-center" style="border:3px solid #fff; margin:0 0 10px 0; border-radius:10px; background:#5B4A82">
                {{ $t('Pages.dashboard.CaDay').toUpperCase() }}<br>
                <base-button 
                  v-if="$apollo.queries.statsCaJG_Lead.loading"
                  loading type="default">
                </base-button>
                <span style="font-size:1.5em" v-if="statsCaJG_Lead">{{ Math.floor(statsCaJG_Lead[0].ca+statsCaJG_Click[0].ca).toLocaleString() }} €</span>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-default btn-simple"
                    :class="{ active: bigLineChart.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        :content="option.name"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i :class="option.icon"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.statsCaWeek.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="statsCaWeek"
                style="height: 100%"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :gradient-colors="bigLineChart.gradientColors"
                :gradient-stops="bigLineChart.gradientStops"
                :extra-options="bigLineChart.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <div class="card card-chart" data="red">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3 text-left">
                <h5 class="card-category" style="color:#fff">WEEDO</h5>
                <h2 class="card-title">MARKET</h2>
              </div>
              <div class="col-sm-3 text-center" style="color: white;border:3px solid #fff; margin:0 0 10px 0; border-radius:10px; background:#3f4ba3">
                {{ $t('Pages.dashboard.CaDay').toUpperCase() }}<br>
                <base-button 
                  v-if="$apollo.queries.getCaAndLeads.loading"
                  loading type="default">
                </base-button>
                <span v-else style="font-size:1.5em">{{ (getCaAndLeads) ? Math.floor(getCaAndLeads[getCaAndLeads.length-1].ca).toLocaleString() : 0 }} €</span>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories2"
                    :key="option.name"
                    class="btn btn-sm btn-default btn-simple"
                    :class="{ active: bigLineChartLeadMarket.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChartMarket(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChartLeadMarket.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <i :class="option.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.getCaAndLeads.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.getCaAndLeads.loading"
                style="height: 100%"
                :chart-data="bigLineChartLeadMarket.chartData"
                :gradient-stops="bigLineChartLeadMarket.gradientStops"
                :extra-options="bigLineChartLeadMarket.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <div class="row">

      <div class="col-lg-6">
        <div class="card card-chart" style="padding:0" >
          <div class="card-body mx-auto" style="background: none; margin: 0; padding:0">
            <video controls poster="img/poster.jpg" width="540" height="327" >
              <source src="img/presentation.mp4" type="video/mp4" style="border-style: none;">
            </video>
          </div>
        </div>
      </div>
      
      <div class="col-lg-6">
        <div class="card card-chart" >
          <div class="card-header">
           <div class="row">
              <div class="col text-left">
                <router-link style="color: darkgrey;" :to="{ name: 'liste programmes My Affil Admin' }">{{ $t('labels.Ourlatestcampaign') }}</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <el-table :data="nouveautes" v-loading="$apollo.queries.nouveautes.loading" row-class-name="booking-table-rows">
              <el-table-column
                min-width="55"
              >
                <template scope="scope">
                  <div class="prog mr-2 float-left">
                    <img
                      v-if="scope.row.hostname != 'plf'"
                      :src="'https://www.weedoit.fr/tracking/partenaire/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      style="cursor: pointer;"
                    />
                    <img
                      v-else
                      :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      style="cursor: pointer;"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="Titre"
                :label="$t('table.columns.Campagne')"
                min-width="160"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="categorie"
                :label="$t('table.columns.Categorie')"
                min-width="115"
                show-overflow-tooltip
              >
                <template scope="scope">
                  <template v-if='ListThematique.find(c => c.value == scope.row.categorie)'>
                    {{ ListThematique.find(c => c.value == scope.row.categorie).categorie }}
                  </template>
                  <template v-else>
                    {{ scope.row.categorie }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column 
                prop label="Action" 
                class="table-striped" 
                min-width="100">
                <template scope="scope">
                  <el-tooltip
                    :content="$t('buttons.BtnModify').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      disabled
                      class="edit btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip
                      :content="$t('buttons.BtnView').toUpperCase()"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="Visualiser(scope.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-tv-2"></i>
                      </base-button>
                    </el-tooltip>
                </template>
              </el-table-column>

            </el-table>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import gql from 'graphql-tag'
import { Table, TableColumn } from 'element-ui';

let bigChartData = [
  [],
  [],
  []
]

let bigChartDataMarket = [
  [],
  []
]

let bigChartLabels = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']
let bigChartLabelsMarket = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']

let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.myAfill,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.myAfill,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.myAfill,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}
let bigChartDatasetOptionsMarket = {
  fill: true,
  borderColor: config.colors.WeedoMarket,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.WeedoMarket,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.WeedoMarket,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}
export default {
  apollo: {
    nouveautes: {
      query: require("src/graphql/NewCampagnes.gql"),
      variables: {
        role: "ad"
      },
      fetchPolicy: 'network-only',
      update: data => (data.nouveautes) ? data.nouveautes.slice(0,4) : [],
      result({ data, loader, networkStatus }) {}
    },
    getCaAndLeads: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/StatCaLeadWM.gql"),
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        
        bigChartLabelsMarket = this.setChartLabels()

        for (let i = 0; i < data.getCaAndLeads.length; i++) {
          bigChartDataMarket[0][i] = data.getCaAndLeads[i].ca
          bigChartDataMarket[1][i] = data.getCaAndLeads[i].leads
        }

        this.initBigChartMarket(0)
        this.Before_QuerygetCaOfMonth = false
      },
      variables () {
        return {
          dateType: 'w'
        }
      }
    },
    getCaOfMonth: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/StatsGlobalMonth.gql"),
      skip() {
        return this.Before_QuerygetCaOfMonth 
      },
      result({ data, loader, networkStatus }) {
        this.Before_QuerygetCaOfMonth = true
      }
    },
    statsCaJG_Lead: { 
      query: gql`query {
        statsCaJG_Lead {
          ca
          marge
        }
      }`,
      update: data => data.statsCaJG_Lead,
      error(error) {
        this.error = error.gqlError.extensions;
      }
    },
    statsCaJG_Click: gql`{
      statsCaJG_Click{
        ca
        marge
      }
    }`,
    statsCaWeek: { 
      query: gql`query {
        statsCaWeek_Click {
          day
          ca
        },
        statsCaWeek_Lead {
          day
          ca
        }
      }`,
      update: data => data,
    },
    statShootOfWeek: gql`{
      statShootOfWeek {
        day
        shoots
      }
    }`,
    statLeadsOfWeek: gql`{
      statLeadsOfWeek {
        day
        leads
      }
    }`,
    User: { 
      query: gql`query {
        me {
          id
          first_name
          last_name
          prenom_client
          image
          society
          email
          law
          state
          profile_account
          activity_area
          orias
          function
          address
          postal_code
          city
          country
          tva_intracommunautaire
          phone
          code_pays
          indicatif
          mobile
          skype
          rcs
          language
          thematic
          url_site
          type_of_broadcast
          Volume
          geographical_area
          Newsletter
          dedicated_email
          target_email
          geolocated_email
          incentive
          click_validation
          co_registration
          white_mark
          site_under
          autres_procedes_diffusion
          api_token
          id_clien_myaffil
          id_clien_mycoreg
          id_clien_datadeal
          id_clien_leadmarket
        }
      }`,
      update: data => data.me,
      skip() {
        return this.skipQuery
      },
    },
  },
  watch: {
    statsCaJG_Lead: function(val) {
      try {
        if(this.error.code == "CLI403") {
          this.skipQuery = false
        }
      } catch (error) {}
    },
    statsCaJG_Lead: function() {
      if(this.statsCaJG_Lead[0].ca == null) {
        let user = JSON.parse(localStorage.getItem('utilisateur'))
        user.database = "test"
        localStorage.setItem('utilisateur', JSON.stringify(user))
      } else {
        let user = JSON.parse(localStorage.getItem('utilisateur'))
        user.database = "prod"
        localStorage.setItem('utilisateur', JSON.stringify(user))
      }
    },
    statsCaWeek: function (val) {
      for (let i = 0; i <  this.statsCaWeek.statsCaWeek_Lead.length; i++) {
        if(this.statsCaWeek.statsCaWeek_Lead[i].ca == null && this.statsCaWeek.statsCaWeek_Click[i].ca == null)
          bigChartData[0][i] = null
        else
          bigChartData[0][i] = (this.statsCaWeek.statsCaWeek_Lead[i].ca + this.statsCaWeek.statsCaWeek_Click[i].ca).toFixed(2)
      }
      bigChartLabels = this.setChartLabels()
      this.initBigChart(0);
    },
    statShootOfWeek:  function (val) {
      this.statShootOfWeek.map(function(value, key) {
        bigChartData[1][key] = value.shoots
      });
    },
    statLeadsOfWeek:  function (val) {
      this.statLeadsOfWeek.map(function(value, key) {
        bigChartData[2][key] = (value.leads == 0) ? null : value.leads
      });
      
    },
    User: function (val) {
      this.User.image = "https://weedoit-media.s3.eu-central-1.amazonaws.com/" + this.User.image 
      location.reload();
    },
  },
  components: {
    LineChart,
    BarChart,
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      ListThematique: this.$t("Database.Thematiques"),
      Mounth: this.$t('labels.Mounths'),
      societe: JSON.parse(localStorage.getItem('utilisateur')).society,
      BASETESCA: null,
      statsCaJG_Click: null,
      statsCaWeek: null,
      statShootOfWeek: null,
      statLeadsOfWeek: null,
      statsCaJG_Lead: '',
      error: null,
      skipQuery: true,
      Before_QuerygetCaOfMonth: true,
      User: null,
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartData[0]
          }],
          labels: bigChartLabels
        },
        extraOptions: {
          ...chartConfigs.purpleChartOptions,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartData[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else if(data.datasets[0].data == bigChartData[1])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " shoots"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 20,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      bigLineChartLeadMarket: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptionsMarket,
            data: bigChartDataMarket[0]
          }],
          labels: bigChartLabelsMarket
        },
        extraOptions: {
          ...chartConfigs.purpleChartOptions,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartDataMarket[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 20,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      purpleLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      greenLineChart: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels:  ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'My First dataset',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0]
            }
          ]
        },
        gradientColors: [
          'rgba(66,134,121,0.15)',
          'rgba(66,134,121,0.0)',
          'rgba(66,134,121,0)'
        ],
        gradientStops: [1, 0.4, 0]
      },
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
          datasets: [
            {
              label: 'Countries',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [0, 0, 0, 0, 0, 0]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [
        { name: 'CA', icon: 'tim-icons icon-coins' }, 
        { name: 'SHOOTS', icon: 'tim-icons icon-spaceship' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    },
    bigLineChartCategories2() {
      return [
        { name: 'CA', icon: 'tim-icons icon-coins' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    }
  },
  methods: {
    Visualiser(row) {
      localStorage.setItem('ProgrammeVisualiser', JSON.stringify(row))
      this.$router.push({name: "Visualiser programme My Affil Admin"})
    },
    imageLoadError(event) {
      event.target.src =
        "/img/missingprofile.jpg";
    },
    setChartLabels() {

      let curr = new Date
      var yesterday = new Date(curr.getTime());
      yesterday.setDate(curr.getDate() - 1);
      let week = []
      for (let i = 1; i <= 7; i++) {
        let first = yesterday.getDate() - yesterday.getDay() + i 
        let day = new Date(yesterday.setDate(first)).toISOString().slice(0, 10).split("-")
        week.push(day[2]+"/"+ this.Mounth[parseInt(day[1]-1)])
      }
      return week
    },
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      // this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    initBigChartMarket(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptionsMarket,
          data: bigChartDataMarket[index]
        }],
        labels: bigChartLabelsMarket
      };
      this.bigLineChartLeadMarket.chartData = chartData;
      this.bigLineChartLeadMarket.activeIndex = index;
    }
  },
  mounted() {
    if (this.$hj) {
      var userId = null;
      this.$hj('identify', userId, {
        societe: this.societe
      })
    }
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  }
};
</script>
<style scoped>
  .prog {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .prog img {
    width: 100%;
  }
</style>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  video {
    outline: 0px;
  }
</style>

<template>
  <div class="container">
    <div class="row">
      <div class="col-lg col-md-6" v-if="logo_client">
        <div data-v-76e78cd0="" class="card card-stats">
            <div class="card-body card-logo">
              <div class="row">
                <div class="col-12">
                  <img :src="logo_client" alt="" />
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-lg col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-4">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-8">
                <div class="numbers">
                  <p class="card-category">TAUX MER SANTÉ</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats_Month.loading">{{ (( Stats_Month.mer_sante / Stats_Month.imp_sante) * 100 || 0).toFixed(2)  }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-4">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-8">
                <div class="numbers">
                  <p class="card-category">TAUX MER ANIMAUX</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats_Month.loading">{{ (( Stats_Month.mer_animaux / Stats_Month.imp_animaux || 0) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-4">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-8">
                <div class="numbers">
                  <p class="card-category">TAUX MER HABITATION</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats_Month.loading">{{ (( Stats_Month.mer_mrh / Stats_Month.imp_mrh || 0) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-4">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-8">
                <div class="numbers">
                  <p class="card-category">TAUX MER AUTO</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats_Month.loading">{{ (( Stats_Month.mer_auto / Stats_Month.imp_auto || 0) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-4">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-8">
                <div class="numbers">
                  <p class="card-category">TAUX MER EMPRUNTEUR</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats_Month.loading">{{ (( Stats_Month.mer_emprunteur / Stats_Month.imp_emprunteur || 0) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-heartbeat"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  SANTÉ
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.Stats_Now.loading">{{ Stats_Now.mer_sante }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.Stats_Month.loading">{{ Stats_Month.mer_sante.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.Stats_Week.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.Stats_Week.loading"
                style="height: 100%"
                :chart-data="lineChartHealth.chartData"
                :extra-options="lineChartHealth.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-paw"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  ANIMAUX
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.Stats_Now.loading">{{ Stats_Now.mer_animaux }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.Stats_Month.loading">{{ Stats_Month.mer_animaux.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.Stats_Week.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.Stats_Week.loading"
                style="height: 100%"
                :chart-data="lineChartAnimaux.chartData"
                :extra-options="lineChartAnimaux.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-home"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  HABITATION
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.Stats_Now.loading">{{ Stats_Now.mer_mrh }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.Stats_Month.loading">{{ Stats_Month.mer_mrh.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.Stats_Week.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.Stats_Week.loading"
                style="height: 100%"
                :chart-data="lineChartMrh.chartData"
                :extra-options="lineChartMrh.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-car"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  AUTO
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.Stats_Now.loading">{{ Stats_Now.mer_auto }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.Stats_Month.loading">{{ Stats_Month.mer_auto.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.Stats_Week.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.Stats_Week.loading"
                style="height: 100%"
                :chart-data="lineChartAuto.chartData"
                :extra-options="lineChartAuto.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="tim-icons icon-bank"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  EMPRUNTEUR
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.Stats_Now.loading">{{ Stats_Now.mer_emprunteur }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.Stats_Month.loading">{{ Stats_Month.mer_emprunteur.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.Stats_Week.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.Stats_Week.loading"
                style="height: 100%"
                :chart-data="lineChartEmprunteur.chartData"
                :extra-options="lineChartEmprunteur.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import { Carousel3d, Slide } from "vue-carousel-3d";


let Mounth = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
]

export default {
  components: {
    LineChart,
    Carousel3d,
    Slide
  },
  apollo: {
    Stats_Now: {
      client: 'MARKET',
      query: gql `
        query ($utilisateur_id: ID!) {
          getTotalMerMyGustave_cleint(utilisateur_id: $utilisateur_id,period: "now") {
            mer_sante
            mer_animaux
            mer_mrh
            mer_auto
            mer_emprunteur
            logo
          }
        }
      `,
      variables () {
        return {
          utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      update: data => data.getTotalMerMyGustave_cleint,
      result({ data, loader, networkStatus }) {
        this.logo_client = data.getTotalMerMyGustave_cleint.logo;
      }
    },
    Stats_Month: {
      client: 'MARKET',
      query: gql `
        query ($utilisateur_id: ID!) {
          getTotalMerMyGustave_cleint(utilisateur_id: $utilisateur_id,period: "month") {
            mer_sante
            mer_animaux
            mer_mrh
            mer_auto
            mer_emprunteur
            imp_sante
            imp_animaux
            imp_mrh
            imp_auto
            imp_emprunteur
          }
        }
      `,
      variables () {
        return {
          utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      update: data => data.getTotalMerMyGustave_cleint
    },
    Stats_Week: {
      client: 'MARKET',
      query: gql `
        query ($utilisateur_id: ID!) {
          Stats_Week:getMerTotalOfWeekMyGustave_cleint(utilisateur_id: $utilisateur_id) {
            mer_sante
            mer_animaux
            mer_mrh   
            mer_auto
            mer_emprunteur
          }
        }
      `,
      variables () {
        return {
          utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      result({ data, loader, networkStatus }) {
        this.lineChartHealth.chartData.datasets[0].data = data.Stats_Week.mer_sante
        this.lineChartAnimaux.chartData.datasets[0].data = data.Stats_Week.mer_animaux
        this.lineChartMrh.chartData.datasets[0].data = data.Stats_Week.mer_mrh
        this.lineChartAuto.chartData.datasets[0].data = data.Stats_Week.mer_auto
        this.lineChartEmprunteur.chartData.datasets[0].data = data.Stats_Week.mer_emprunteur
      },
      update: data => data.getMerTotalOfWeekMyGustave_cleint
    },
  },
  data() {
    return {
      slides2: 4,
      logo_client: null,
      lineChartHealth: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartAnimaux: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartMrh: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartAuto: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartEmprunteur: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    }
  },
  created() {
    this.lineChartHealth.chartData.labels =
    this.lineChartAnimaux.chartData.labels =
    this.lineChartMrh.chartData.labels = 
    this.lineChartAuto.chartData.labels = 
    this.lineChartEmprunteur.chartData.labels = this.setChartLabels();
  },
  methods: {
    setChartLabels() {
      let curr = new Date
      var yesterday = new Date(curr.getTime());
      yesterday.setDate(curr.getDate() - 1);
      let week = []
      for (let i = 1; i <= 7; i++) {
        let first = yesterday.getDate() - yesterday.getDay() + i 
        let day = new Date(yesterday.setDate(first)).toISOString().slice(0, 10).split("-")
        week.push(day[2]+"/"+ Mounth[parseInt(day[1]-1)])
      }
      return week
    },
  }
}
</script>


<style scoped>
.card-category {
  font-size: 11px !important;
}
.card-logo {
  background: #f9f9fb;
  border-radius: 0.2857rem;
  height: 86px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
}
.card-stats.mongustave .info-icon.icon-primary {
  background: #ff6b00;
}

.card-stats.mongustave .info-icon i {
  padding: 0px;
  color: #fff;
  font-size: 32px;
  line-height: 50px;
}

.card-stats .numbers .card-category {
  font-size: 13px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
}

.card-stats.mongustave .card-title {
  font-size: 30px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-stats.mongustave .numbers {
  text-align: center;
}

.card-header.mongustave {
  position: relative;
  background: #217fb2; /* fallback color if gradients are not supported*/
  background: -webkit-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: -moz-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: -o-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: linear-gradient(100deg, #217fb2, #0cb3c6);
}

.card .card-header:not([data-background-color]) {
  background-color: none;
}

.card .card-header {
  color: #fff;
  padding: 12px 0px 11px 15px;
}

.col_fas {
  color: #fff;
  font-size: 35px;
  width: 51px;
  height: 51px;
  background: #203b93;
  border-radius: 100%;
  margin: auto;
  line-height: 51px;
  border: 2px solid #e96d12;
}

.card-header.mongustave {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 23px;
  line-height: 29px;
}

.card-header.mongustave strong {
  font-weight: 700;
  font-size: 30px;
}

.col_white {
  padding: 0px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
  border: 3px solid #fff;
  font-size: 14px;
  margin: auto;
  line-height: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.col_white strong {
  font-size: 30px;
}
</style>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  video {
    outline: 0px;
  }
  #carousel3d_dashboard .carousel-3d-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    padding: 0px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    border-radius: 5px;
    height: 100%; 
    width: 100%;
    padding: 0px;
    position: absolute;
    margin: 0 auto;transform-style: none !important;
    -webkit-perspective: none !important;
    -moz-perspective: none !important;
    perspective: none !important;
  }

  .carousel-3d-slide .card {
    margin-bottom: 0px;
  }
  
  .carousel-3d-controls a.prev,
  .carousel-3d-controls a.next {
    color: #fff;
  }
</style>
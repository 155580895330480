<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-5">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-7">
                <div class="numbers">
                  <p class="card-category">TAUX MER SANTÉ</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats.loading">{{ (( Stats.mer_sante / Stats.affichage_sante) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-5">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-7">
                <div class="numbers">
                  <p class="card-category">TAUX MER ANIMAUX</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats.loading">{{ (( Stats.mer_animaux / Stats.affichage_animaux) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-5">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-7">
                <div class="numbers">
                  <p class="card-category">TAUX MER MRH</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats.loading">{{ (( Stats.mer_mrh / Stats.affichage_mrh) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="card card-stats mongustave">
          <div class="card-body card-stat">
            <div class="row">
              <div class="col-5">
                <div class="info-icon text-center icon-primary">
                  <i class="fas fa-chart-bar"></i>
                </div>
              </div>
              <div class="col-7">
                <div class="numbers">
                  <p class="card-category">TAUX MER AUTO</p>
                  <h3 class="card-title" v-if="!$apollo.queries.Stats.loading">{{ (( Stats.mer_auto / Stats.affichage_auto) * 100).toFixed(2) }} %</h3>
                  <base-button v-else loading type="default"></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-heartbeat"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  SANTÉ
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.GlobalMERSante.loading">{{ GlobalMERSante.now }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.GlobalMERSante.loading">{{ formatThounsends(GlobalMERSante.month) }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.MerWeekSante.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.MerWeekSante.loading"
                style="height: 100%"
                :chart-data="lineChartHealth.chartData"
                :extra-options="lineChartHealth.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-paw"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  ANIMAUX
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.GlobalMERAnimaux.loading">{{ GlobalMERAnimaux.now }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.GlobalMERAnimaux.loading">{{ GlobalMERAnimaux.month.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.MerWeekAnimaux.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.MerWeekAnimaux.loading"
                style="height: 100%"
                :chart-data="lineChartAnimaux.chartData"
                :extra-options="lineChartAnimaux.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-home"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  HABITATION
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.GlobalMERMrh.loading">{{ GlobalMERMrh.now }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.GlobalMERMrh.loading">{{ GlobalMERMrh.month.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.MerWeekMrh.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.MerWeekMrh.loading"
                style="height: 100%"
                :chart-data="lineChartMrh.chartData"
                :extra-options="lineChartMrh.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="fas fa-car"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  AUTO
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.GlobalMERAuto.loading">{{ GlobalMERAuto.now }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.GlobalMERAuto.loading">{{ GlobalMERAuto.month.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.MerWeekAuto.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.MerWeekAuto.loading"
                style="height: 100%"
                :chart-data="lineChartAuto.chartData"
                :extra-options="lineChartAuto.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="tim-icons icon-bank"></i>
                </div>
                <div>
                  <strong class="text-uppercase">MER</strong><br />
                  EMPRUNTEUR
                </div>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU JOUR<br />
                <strong v-if="!$apollo.queries.GlobalMEREmprunteur.loading">{{ GlobalMEREmprunteur.now }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                MER DU MOIS<br />
                <strong v-if="!$apollo.queries.GlobalMEREmprunteur.loading">{{ GlobalMEREmprunteur.month.toLocaleString() }}</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.MerWeekEmprunteur.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.MerWeekEmprunteur.loading"
                style="height: 100%"
                :chart-data="lineChartEmprunteur.chartData"
                :extra-options="lineChartEmprunteur.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div data="mongustave" class="card card-chart">
          <div class="card-header mongustave">
            <div class="row mr-0">
              <div class="col-sm-5 text-left">
                <div class="col_fas text-center float-left mr-3">
                  <i class="tim-icons icon-coins"></i>
                </div>
                <!-- <div>
                  <strong class="text-uppercase">CA</strong>
                </div> -->
              </div>
              <div class="col-sm-3 text-left text-center">
                <!-- CA DU MOIS<br /> -->
                <!-- <strong>0</strong> -->
                <!-- <base-button v-else loading type="default"></base-button> -->
              </div>
              <div class="col-sm-3 col_white text-left text-center">
                CA DU JOUR<br />
                <strong v-if="!SkipQuery_CA">{{ formatThounsends(Ca_Global_Day,true,0) }} €</strong>
                <base-button v-else loading type="default"></base-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="SkipQuery_CA" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <bar-chart
                v-if="!SkipQuery_CA"
                style="height: 100%"
                :chart-data="barChartCa.chartData"
                :extra-options="barChartCa.extraOptions"
              >
              </bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from 'src/components/Charts/BarChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import moment from 'moment'

let Mounth = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
]

export default {
  components: {
    LineChart,
    BarChart
  },
  apollo: {
    GlobalMEREmprunteur: {
      client: 'MARKET',
      query: gql`query {
        month:getTotalMerMyGustave(
          period: "month",
          produit: 5
        )
        now:getTotalMerMyGustave(
          period: "now",
          produit: 5
        )
      }`,
      update: data => data
    },
    GlobalMERAuto: {
      client: 'MARKET',
      query: gql`query {
        month:getTotalMerMyGustave(
          period: "month",
          produit: 4
        )
        now:getTotalMerMyGustave(
          period: "now",
          produit: 4
        )
      }`,
      update: data => data
    },
    GlobalMERMrh: {
      client: 'MARKET',
      query: gql`query {
        month:getTotalMerMyGustave(
          period: "month",
          produit: 3
        )
        now:getTotalMerMyGustave(
          period: "now",
          produit: 3
        )
      }`,
      update: data => data
    },
    GlobalMERSante: {
      client: 'MARKET',
      query: gql`query {
        month:getTotalMerMyGustave(
          period: "month",
          produit: 1
        )
        now:getTotalMerMyGustave(
          period: "now",
          produit: 1
        )
      }`,
      update: data => data
    },
    GlobalMERAnimaux: {
      client: 'MARKET',
      query: gql`query {
        month:getTotalMerMyGustave(
          period: "month",
          produit: 2
        )
        now:getTotalMerMyGustave(
          period: "now",
          produit: 2
        )
      }`,
      update: data => data
    },
    MerWeekSante: {
      client: 'MARKET',
      query: gql`query {
        MerWeekSante: getMerTotalOfWeekMyGustave(
          produit: 1
        )
      }`,
      result({ data, loader, networkStatus }) {
        this.lineChartHealth.chartData.datasets[0].data = data.MerWeekSante
      }
    },
    MerWeekAnimaux: {
      client: 'MARKET',
      query: gql`query {
        MerWeekAnimaux: getMerTotalOfWeekMyGustave(
          produit: 2
        )
      }`,
      result({ data, loader, networkStatus }) {
        this.lineChartAnimaux.chartData.datasets[0].data = data.MerWeekAnimaux
      }
    },
    MerWeekMrh: {
      client: 'MARKET',
      query: gql`query {
        MerWeekMrh: getMerTotalOfWeekMyGustave(
          produit: 3
        )
      }`,
      result({ data, loader, networkStatus }) {
        this.lineChartMrh.chartData.datasets[0].data = data.MerWeekMrh
      }
    },
    MerWeekEmprunteur: {
      client: 'MARKET',
      query: gql`query {
        MerWeekEmprunteur: getMerTotalOfWeekMyGustave(
          produit: 5
        )
      }`,
      result({ data, loader, networkStatus }) {
        this.lineChartEmprunteur.chartData.datasets[0].data = data.MerWeekEmprunteur
      }
    },
    MerWeekAuto: {
      client: 'MARKET',
      query: gql`query {
        MerWeekAuto: getMerTotalOfWeekMyGustave(
          produit: 4
        )
      }`,
      result({ data, loader, networkStatus }) {
        this.lineChartAuto.chartData.datasets[0].data = data.MerWeekAuto
      }
    },
    Stats: {
      client: 'MARKET',
      query: gql `
        query ($date_debut: String!, $date_fin: String!) {
          getStatisticsMyGustaveProducts(date_debut: $date_debut, date_fin: $date_fin) {
            affichage_sante
            affichage_animaux
            affichage_mrh
            affichage_auto
            mer_sante
            mer_animaux
            mer_mrh    
            mer_auto    
          }
        }
      `,
      variables () {
        return {
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
          date_debut: moment().clone().startOf('month').format('YYYY-MM-DD'),
          date_fin: moment().format('YYYY-MM-DD')
        }
      },
      update: data => data.getStatisticsMyGustaveProducts,
    },
  },
  data() {
    return {
      SkipQuery_CA: true,
      Ca_Global_Day: 0,
      lineChartHealth: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartAnimaux: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartMrh: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartAuto: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChartEmprunteur: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Mer',
              fill: true,
              borderColor: config.colors.WeedoMarket,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.WeedoMarket,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      barChartCa: {
          chartData: {
            labels: [],
            datasets: [
              {
                label: 'SANTE',
                fill: true,
                backgroundColor: "#DD4E01",
                hoverBackgroundColor: "#DD4E01",
                borderColor: "#DD4E01",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: []
              },
              {
                label: 'ANIMAUX',
                fill: true,
                backgroundColor: config.colors.WeedoMarket,
                hoverBackgroundColor: config.colors.WeedoMarket,
                borderColor: config.colors.WeedoMarket,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: []
              },
              {
                label: 'MRH',
                fill: true,
                backgroundColor: "#088A08",
                hoverBackgroundColor: "#088A08",
                borderColor: "#088A08",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: []
              },
              {
                label: 'AUTO',
                fill: true,
                backgroundColor: "#374c9b",
                hoverBackgroundColor: "#374c9b",
                borderColor: "#374c9b",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: []
              },
              {
                label: 'EMPRUNTEUR',
                fill: true,
                backgroundColor: "#B16B5C",
                hoverBackgroundColor: "#B16B5C",
                borderColor: "#B16B5C",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: []
              }
            ]
          },
          extraOptions: {
            ...chartConfigs.barChartOptionsGradient,
            legend: {
              display: true
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  return tooltipItem.value + " €";
                }
              },
              backgroundColor: '#f5f5f5',
              titleFontColor: '#333',
              bodyFontColor: '#666',
              bodySpacing: 4,
              xPadding: 12,
              mode: 'nearest',
              intersect: 0,
              position: 'nearest'
            },
          }
        },
    }
  },
  created() {
    this.lineChartHealth.chartData.labels =
    this.lineChartAnimaux.chartData.labels =
    this.lineChartMrh.chartData.labels =
    this.lineChartAuto.chartData.labels =
    this.lineChartEmprunteur.chartData.labels =
    this.barChartCa.chartData.labels = this.setChartLabels();

    this.SendCa_Query();
  },
  methods: {
    setChartLabels() {
      let curr = new Date
      var yesterday = new Date(curr.getTime());
      yesterday.setDate(curr.getDate() - 1);
      let week = []
      for (let i = 1; i <= 7; i++) {
        let first = yesterday.getDate() - yesterday.getDay() + i 
        let day = new Date(yesterday.setDate(first)).toISOString().slice(0, 10).split("-")
        week.push(day[2]+"/"+ Mounth[parseInt(day[1]-1)])
      }
      return week
    },
    async SendCa_Query() {
      moment.locale('fr');
      var currentDate = moment();
      var weekStart = currentDate.clone().startOf('week');

      let CA_Sante = []
      let CA_Animaux = []
      let CA_Mrh = []
      let CA_Auto = []
      let CA_Emprunteur = []
      
      do {
        await this.$apollo.query({
          client: 'MARKET',
          query: gql `
            query ($date_debut: String!, $date_fin: String!) {
              Sante:getStatisticsMyGustaveHealthClients(date_debut: $date_debut, date_fin: $date_fin) {
                mer_number
                prix_lead
              }
              Animaux:getStatisticsMyGustaveAnimalsClients(date_debut: $date_debut, date_fin: $date_fin) {
                mer_number
                prix_lead
              }
              Mrh:getStatisticsMyGustaveMRH(date_debut: $date_debut, date_fin: $date_fin) {
                mer_number
                prix_lead
              }
              Auto:getStatisticsMyGustaveAUTO(date_debut: $date_debut, date_fin: $date_fin) {
                mer_number
                prix_lead
              }
              Emprunteur:getStatisticsMyGustaveEmprunteur(date_debut: $date_debut, date_fin: $date_fin) {
                mer_number
                prix_lead
              }
            }
          `,
          variables: {
            date_debut: weekStart.format('YYYY-MM-DD'),
            date_fin: weekStart.format('YYYY-MM-DD')
          },
        }).then(data => {
          CA_Sante.push(
            data.data.Sante.reduce( function(a, b){
              return a + (b.mer_number*b.prix_lead);
            }, 0)
          )

          CA_Animaux.push(
            data.data.Animaux.reduce( function(a, b){
              return a + (b.mer_number*b.prix_lead);
            }, 0)
          )
          
          CA_Mrh.push(
            data.data.Mrh.reduce( function(a, b){
              return a + (b.mer_number*b.prix_lead);
            }, 0)
          )

          CA_Auto.push(
            data.data.Auto.reduce( function(a, b){
              return a + (b.mer_number*b.prix_lead);
            }, 0)
          )

          CA_Emprunteur.push(
            data.data.Emprunteur.reduce( function(a, b){
              return a + (b.mer_number*b.prix_lead);
            }, 0)
          )

        }).catch(error => {
          this.SkipQuery_CA = false
          console.log(error)
        })
      } while(weekStart.add(1, 'days').diff(currentDate) < 0);

      this.Ca_Global_Day =  CA_Sante[CA_Sante.length - 1] + 
                            CA_Animaux[CA_Animaux.length - 1] + 
                            CA_Mrh[CA_Mrh.length - 1] + 
                            CA_Auto[CA_Auto.length - 1] +
                            CA_Emprunteur[CA_Emprunteur.length - 1]

      this.barChartCa.chartData.datasets[0].data = CA_Sante
      this.barChartCa.chartData.datasets[1].data = CA_Animaux
      this.barChartCa.chartData.datasets[2].data = CA_Mrh
      this.barChartCa.chartData.datasets[3].data = CA_Auto
      this.barChartCa.chartData.datasets[4].data = CA_Emprunteur

      this.SkipQuery_CA = false
    },
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value :value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  }
}
</script>


<style scoped>
.card-logo {
  background: #f9f9fb;
  border-radius: 0.2857rem;
  height: 92px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
}
.card-stats.mongustave .info-icon.icon-primary {
  background: #ff6b00;
}

.card-stats.mongustave .info-icon i {
  padding: 0px;
  color: #fff;
  font-size: 32px;
  line-height: 50px;
}

.card-stats .numbers .card-category {
  font-size: 14px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
}

.card-stats.mongustave .card-title {
  font-size: 30px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-stats.mongustave .numbers {
  text-align: center;
}

.card-header.mongustave {
  position: relative;
  background: #217fb2; /* fallback color if gradients are not supported*/
  background: -webkit-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: -moz-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: -o-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: linear-gradient(100deg, #217fb2, #0cb3c6);
}

.card .card-header:not([data-background-color]) {
  background-color: none;
}

.card .card-header {
  color: #fff;
  padding: 12px 0px 11px 15px;
}

.col_fas {
  color: #fff;
  font-size: 35px;
  width: 51px;
  height: 51px;
  background: #203b93;
  border-radius: 100%;
  margin: auto;
  line-height: 51px;
  border: 2px solid #e96d12;
}

.card-header.mongustave {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 23px;
  line-height: 29px;
}

.card-header.mongustave strong {
  font-weight: 700;
  font-size: 30px;
}

.col_white {
  padding: 0px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
  border: 3px solid #fff;
  font-size: 14px;
  margin: auto;
  line-height: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.col_white strong {
  font-size: 30px;
}
</style>
let Offers;
let Offers_Speciale;

Offers_Speciale = {
    link: "https://weedoit.digital/2021/10/07/webinar-lancement-de-weedo-max-le-club-de-fidelite-2/?utm_source=NP&utm_medium=banner&utm_campaign=replay-weedoMax",
    img: "img/weedoMax.png",
    btnText: "EN SAVOIR PLUS",
    color: "blue"
};

Offers = [
    {
        link: "https://weedoit.digital/2024/06/19/5-campagnes-cles-pour-booster-votre-activite-daffilie-2/?utm_source=plateforme&utm_medium=banner&utm_campaign=top-camp-19062024",
        img: "img/campagnes_weedoPerf_11.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/01/25/meilleurs-voeux-2022-2/?utm_source=plateforme&utm_medium=banner&utm_campaign=voeux_2024",
        img: "img/meilleurs-voeux.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2022/03/14/quest-ce-que-tu-attends-pour-profiter-des-avantages-weedo-max/?utm_source=NP&utm_medium=banner&utm_campaign=challenge-weedo-max-nouveaux-editeurs&utm_id=Challenge+nouveaux+%C3%A9diteurs",
        img: "img/club_fidelite_WMAX.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2022/01/18/weedo-max-plus-tu-shootes-plus-tu-gagnes/?utm_source=NP&utm_medium=banner&utm_campaign=challenge-weedo-max&utm_id=Challenge+WEEDO+Max",
        img: "img/WeedoMax-Shootes.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "Vedio presentation",
        img: "",
        btnText: "",
        color: ""
    },
    {
        link: "https://weedoit.digital/2022/01/20/ils-ont-joue-ils-ont-gagne/?utm_source=NP&utm_medium=banner&utm_campaign=weedo-max&utm_id=weedo+max+cadeaux",
        img: "img/weedoMax2.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2020/11/30/resiliation-infra-annuelle-une-opportunite-pour-collecter-des-leads-assurance/",
        img: "img/resiliation-infra.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/07/31/vacances-ete-2024/?utm_source=plateforme&utm_medium=banner&utm_campaign=com-ete-2024",
        img: "img/vacances-ete.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    }
];

export {
    Offers,
    Offers_Speciale
}

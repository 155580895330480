<template>
  <div class="container">
    <div class="content pb-0">
      <div class="box-weedtitle text-center">
        <img src="img/cadeau_img/weedomax.png" alt="" />
        <span class="d-block text-transform">Weedo MAX</span>
      </div>

      <div class="content-weedcadeau">
        <span class="d-block weedcadeau text-center"
          >Weedo IT récompense votre fidélité</span
        >

        <div class="txt-weedcadeau text-center">
          à partir du 1er juillet 2021, Weedo IT vous offre la possibilité de
          bénéficier de nombreux cadeaux,<br />
          grâce à notre club fidélité.<br /><br />

          Ces points sont calculés mensuellement. <br /><br />

          Les avantages sont nombreux et variés.<br /><br />

          Vous pourrez utiliser votre solde de points sur une gamme de produits
          tendances.
        </div>
        <span v-if="this.urlCadeau !== null && this.urlCadeau !== 'null'">
          <ul class="row m-0 list-1">
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <a style="cursor: pointer">
                <div class="list-gif" @click="generateUrlCadaux">
                  <div class="img_gif">
                    <img src="img/cadeau_img/leads-services.png" alt="" />
                  </div>
                  <span class="d-block">Leads & <br />services</span>
                </div>
              </a>
            </li>
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <a style="cursor: pointer">
                <div class="list-gif">
                  <div class="img_gif" @click="generateUrlCadaux">
                    <img src="img/cadeau_img/high-tech.png" alt="" />
                  </div>
                  <span class="d-block">High tech</span>
                </div>
              </a>
            </li>
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <a style="cursor: pointer">
                <div class="list-gif">
                  <div class="img_gif" @click="generateUrlCadaux">
                    <img src="img/cadeau_img/cuisine.png" alt="" />
                  </div>
                  <span class="d-block"
                    >Cuisine & <br />
                    électroménager</span
                  >
                </div>
              </a>
            </li>
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <a style="cursor: pointer">
                <div class="list-gif">
                  <div class="img_gif" @click="generateUrlCadaux">
                    <img src="img/cadeau_img/beaute.png" alt="" />
                  </div>
                  <span class="d-block">Mode & <br />beauté</span>
                </div>
              </a>
            </li>
          </ul>

          <ul class="row m-0 list-2 pb-4">
            <li class="col-lg-4 col-md-6 col-sm-6 p-0 text-center">
              <a style="cursor: pointer">
                <div class="list-gif">
                  <div class="img_gif" @click="generateUrlCadaux">
                    <img src="img/cadeau_img/maison.png" alt="" />
                  </div>
                  <span class="d-block">Maison & <br />jardin</span>
                </div>
              </a>
            </li>
            <li class="col-lg-4 col-md-6 col-sm-6 p-0 text-center">
              <a style="cursor: pointer">
                <div class="list-gif">
                  <div class="img_gif" @click="generateUrlCadaux">
                    <img src="img/cadeau_img/sport-loisir.png" alt="" />
                  </div>
                  <span class="d-block"
                    >Sport & <br />
                    loisir</span
                  >
                </div>
              </a>
            </li>
            <li class="col-lg-4 col-md-6 col-sm-6 p-0 text-center">
              <a style="cursor: pointer">
                <div class="list-gif">
                  <div class="img_gif" @click="generateUrlCadaux">
                    <img src="img/cadeau_img/gastronomie.png" alt="" />
                  </div>
                  <span class="d-block"
                    >Vin & <br />
                    gastronomie
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </span>
        <span v-else>
          <ul class="row m-0 list-1">
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <div class="list-gif">
                <a
                  v-bind:href="urlCadeau"
                  target="_blank"
                  style="pointer-events: none"
                  ><div class="img_gif">
                    <img src="img/cadeau_img/leads-services.png" alt="" />
                  </div>
                  <span class="d-block">Leads & <br />services</span></a
                >
              </div>
            </li>
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <div class="list-gif">
                <a
                  v-bind:href="urlCadeau"
                  target="_blank"
                  style="pointer-events: none"
                  ><div class="img_gif">
                    <img src="img/cadeau_img/high-tech.png" alt="" />
                  </div>
                  <span class="d-block">High tech</span></a
                >
              </div>
            </li>
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <div class="list-gif">
                <a
                  v-bind:href="urlCadeau"
                  target="_blank"
                  style="pointer-events: none"
                  ><div class="img_gif">
                    <img src="img/cadeau_img/cuisine.png" alt="" />
                  </div>
                  <span class="d-block"
                    >Cuisine & <br />
                    électroménager</span
                  ></a
                >
              </div>
            </li>
            <li class="col-lg-3 col-md-6 col-sm-6 p-0 text-center">
              <div class="list-gif">
                <a
                  v-bind:href="urlCadeau"
                  target="_blank"
                  style="pointer-events: none"
                  ><div class="img_gif">
                    <img src="img/cadeau_img/beaute.png" alt="" />
                  </div>
                  <span class="d-block">Mode & <br />beauté</span></a
                >
              </div>
            </li>
          </ul>

          <ul class="row m-0 list-2 pb-4">
            <li class="col-lg-4 col-md-6 col-sm-6 p-0 text-center">
              <div class="list-gif">
                <a
                  v-bind:href="urlCadeau"
                  target="_blank"
                  style="pointer-events: none"
                  ><div class="img_gif">
                    <img src="img/cadeau_img/maison.png" alt="" />
                  </div>
                  <span class="d-block">Maison & <br />jardin</span></a
                >
              </div>
            </li>
            <li class="col-lg-4 col-md-6 col-sm-6 p-0 text-center">
              <div class="list-gif">
                <a
                  v-bind:href="urlCadeau"
                  target="_blank"
                  style="pointer-events: none"
                  ><div class="img_gif">
                    <img src="img/cadeau_img/sport-loisir.png" alt="" />
                  </div>
                  <span class="d-block"
                    >Sport & <br />
                    loisir</span
                  ></a
                >
              </div>
            </li>
            <li class="col-lg-4 col-md-6 col-sm-6 p-0 text-center">
              <div class="list-gif">
                <a
                  v-bind:href="urlCadeau"
                  target="_blank"
                  style="pointer-events: none"
                  ><div class="img_gif">
                    <img src="img/cadeau_img/gastronomie.png" alt="" />
                  </div>
                  <span class="d-block"
                    >Vin & <br />
                    gastronomie
                  </span></a
                >
              </div>
            </li>
          </ul>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      urlCadeau: localStorage.url_cadeau,
      skipQuery: true,
    };
  },
  apollo: {
    getCompteCadeaux: {
      client: "AUTH",
      query: gql`
        query ($user_id: Int!, $droit: String) {
          getCompteCadeaux(user_id: $user_id, droit: $droit) {
            url
            solde
            message
          }
        }
      `,
      skip() {
        return this.skipQuery;
      },
      variables() {
        return {
          user_id: JSON.parse(localStorage.getItem("utilisateur")).id,
          droit: JSON.parse(localStorage.getItem("utilisateur")).law,
        };
      },
      result({ data, loader, networkStatus }) {
        this.skipQuery = true;
        localStorage.url_cadeau = data.getCompteCadeaux.url;
      },
      fetchPolicy: "network-only",
    },
  },
  methods: {
    generateUrlCadaux() {
      this.skipQuery = false;
      window.open(localStorage.url_cadeau, "_blank");
    },
  },
};
</script>
<style>
.sidebar_ico.weedomax {
  border-color: #ec277a;
}

.active_menu.weedomax a,
.sidebar_ico.weedomax a {
  background: #ec277a !important;
}

.box-weedtitle {
  background: #27293d;
  max-width: 438px;
  margin: auto auto 37px auto;
  padding: 50px 0 33px 0px;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.box-weedtitle span {
  font-size: 30px;
  color: #fff;
  font-weight: normal !important;
  padding-top: 36px;
}

.content-weedcadeau {
  background: #27293d;
  max-width: 1275px;
  margin: auto;
  background: #27293d;
}

.content-weedcadeau .weedcadeau {
  margin-bottom: 20px;
  background: #ec277a;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
  font-size: 34px;
  color: #fff;
  font-weight: normal !important;
  padding: 12px 0px 11px 0px;
}

.txt-weedcadeau {
  color: #dddddd;
  font-size: 18px;
  line-height: 24px;
  padding: 50px 0px 55px 0px;
}

.content-weedcadeau ul {
  padding: 50px 0 0 0;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 82%;
  margin: auto !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.content-weedcadeau .list-2 {
  width: 62%;
}

.content-weedcadeau li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-bottom: 15px !important;
}

.content-weedcadeau li .list-gif {
  width: 174px;
  border: 2px solid #ec277a;
  background: #ffffff;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  vertical-align: text-top;
  color: #ec277a;
  font-size: 16px;
  min-height: 109px;
  height: auto;
  margin: auto;
}

.content-weedcadeau li .list-gif a {
  color: #ec277a;
}

.content-weedcadeau li .list-gif span {
  line-height: 20px;
  padding-bottom: 6px;
}

.img_gif {
  height: 60px;
  position: relative;
}

.img_gif img {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 991.98px) {
  .content-weedcadeau ul {
    width: 100% !important;
  }
  .content-weedcadeau .list-2 {
    padding-top: 0px;
  }
}

@media (max-width: 575.98px) {
  .content-weedcadeau .weedcadeau {
    font-size: 25px;
  }
  .content-weedcadeau ul {
    padding: 0px !important;
  }
}

@media (max-width: 400px) {
  .content-weedcadeau .weedcadeau {
    font-size: 20px;
  }
}
</style>

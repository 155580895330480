<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <stats-card
          :title="(CaMyAffilEdit!=null) ? Math.floor(CaMyAffilEdit.statsCaJG_Lead[0].ca + CaMyAffilEdit.statsCaJG_Click[0].ca).toLocaleString() + ' €' : '0 €'"
          :loading='$apollo.queries.CaMyAffilEdit.loading'
          :sub-title="$t('Pages.dashboard.CaGlobalDay')"
          type="primary"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
      <div class="col-lg-6 col-md-12">
        <stats-card
          :title="(CaMyAffilEdit!=null) ? Math.floor(CaMyAffilEdit.statsCaMG_Lead[0].ca + CaMyAffilEdit.statsCaMG_Click[0].ca).toLocaleString() + ' €' : '0 €'"
          :loading='$apollo.queries.CaMyAffilEdit.loading'
          :sub-title="$t('Pages.dashboard.CaGlobalMonth')"
          type="primary"
          icon="tim-icons icon-single-02"
        >
        </stats-card>
      </div>
    </div>

    <!-- <transition name="fade" appear>
      <PopupWeedoMax/>
    </transition> -->

    <!-- Small charts -->
    <div class="row">
      <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <div class="card card-chart" data="blue">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3 text-left">
                <h5 class="card-category" style="color:#fff">WEEDO</h5>
                <h2 class="card-title">PERF</h2>
              </div>
              <div class="col-sm-3 text-center" style="border:3px solid #fff; margin:0 0 10px 0; border-radius:10px; background:#5B4A82">
                {{ $t('Pages.dashboard.CaDay').toUpperCase() }}<br>
                <base-button 
                  v-if="$apollo.queries.statsCaJG_Lead.loading"
                  loading type="default">
                </base-button>
                <span style="font-size:1.5em" v-if="statsCaJG_Lead">{{ Math.floor(statsCaJG_Lead[0].ca+statsCaJG_Click[0].ca) }} €</span>
                <span style="font-size:1.5em" v-else-if='!statsCaJG_Lead && !$apollo.queries.statsCaJG_Lead.loading'>0 €</span>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-default btn-simple"
                    :class="{ active: bigLineChart.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        :content="option.name"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i :class="option.icon"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.statsCaWeek.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="statsCaWeek"
                style="height: 100%"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :gradient-colors="bigLineChart.gradientColors"
                :gradient-stops="bigLineChart.gradientStops"
                :extra-options="bigLineChart.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-lg-6">
        <div class="card card-chart" :data="Offers_Speciale.color">
          <div class="card-body text-center" style="background: none">
            <img :src="Offers_Speciale.img"  width="460" height="250">
            <a class="btn btn-warning mt-4 pull-right " :href="Offers_Speciale.link" target="_blank">{{ Offers_Speciale.btnText }}</a>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card card-chart">
          <carousel-3d id="carousel3d_dashboard" style="margin: 0" :autoplay="true" :autoplay-timeout="5000" :perspective="0" :controls-visible="true" :width="540" :height="335" :display="1">
            <slide v-for="(offer, i) in Offers_af" :index="i" :key="i">
              <template v-if="offer.link == 'Vedio presentation'">
                <div class="card card-chart" style="width: 100%; height: 100%;">
                  <VedioPresentation />
                </div>
              </template>
              <template v-else>
                <div class="card card-chart" :data="offer.color" style="width: 100%; height: 100%;">
                  <div class="card-body text-center" style="background: none">
                    <img :src="offer.img"  width="460" height="250">
                    <a class="btn btn-default mt-4 pull-right" :href="offer.link" target="_blank">
                      {{ offer.btnText }}
                    </a>
                  </div>
                </div>
              </template>
            </slide>
          </carousel-3d>
        </div>
      </div>

      <!-- <div class="col-lg-6" :class="{ 'text-right': isRTL }">
        <div class="card card-chart" data="red">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3 text-left">
                <h5 class="card-category" style="color:#fff">WEEDO</h5>
                <h2 class="card-title">MARKET</h2>
              </div>
              <div class="col-sm-3 text-center" style="color: white;border:3px solid #fff; margin:0 0 10px 0; border-radius:10px; background:#3f4ba3">
                {{ $t('Pages.dashboard.CaDay').toUpperCase() }}<br>
                <span style="font-size:1.5em">0 €</span>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories2"
                    :key="option.name"
                    class="btn btn-sm btn-default btn-simple"
                    :id="index"
                  >
                    <input
                      type="radio"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <i :class="option.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-area">
              <line-chart
                style="height: 100%"
                :chart-data="greenLineChart.chartData"
                :gradient-stops="greenLineChart.gradientStops"
                :extra-options="greenLineChart.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div> -->
      
      <div class="col-lg-6">
        <div class="card card-chart" >
          <div class="card-header">
           <div class="row">
              <div class="col text-left">
                <router-link style="color: darkgrey;" :to="{ name: 'liste programmes My Affil Editeur' }">{{ $t('labels.Ourlatestcampaign') }}</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <el-table :data="nouveautes" v-loading="$apollo.queries.nouveautes.loading" row-class-name="booking-table-rows">
              <el-table-column
                min-width="55"
              >
                <template scope="scope">
                  <div class="prog mr-2 float-left">
                    <img
                      v-if="scope.row.hostname != 'plf'"
                      :src="'https://www.weedoit.fr/tracking/partenaire/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      style="cursor: pointer;"
                    />
                    <img
                      v-else
                      :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      style="cursor: pointer;"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="Titre"
                :label="$t('table.columns.Title')"
                min-width="160"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="categorie"
                :label="$t('table.columns.Categorie')"
                min-width="115"
                show-overflow-tooltip
              >
                <template scope="scope">
                  <template v-if='ListThematique.find(c => c.value == scope.row.categorie)'>
                    {{ ListThematique.find(c => c.value == scope.row.categorie).categorie }}
                  </template>
                  <template v-else>
                    {{ scope.row.categorie }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column 
                prop label="Action" 
                class="table-striped" 
                min-width="100">
                <template scope="scope">
                  <el-tooltip
                    :content="$t('buttons.BtnView').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="Visualiser(scope.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-tv-2"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>

            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import gql from 'graphql-tag'
import { Table, TableColumn } from 'element-ui';
import VedioPresentation from './LayoutAnnonceur/VedioPresentation'
import { Carousel3d, Slide } from "vue-carousel-3d";
// import PopupWeedoMax from './pop-up_Weedomax.vue';
import { Offers, Offers_Speciale } from "./Offers-af";


let bigChartData = [
  [],
  [],
  []
]
let bigChartLabels = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.myAfill,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.myAfill,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.myAfill,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}
export default {
  apollo: {
    CaMyAffilEdit: {
      query: require("src/graphql/CaMyAffilEdit.gql"),
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return this.id_client === 0
      },
      update: data => data
    },
    nouveautes: {
      query: require("src/graphql/NewCampagnes.gql"),
      variables: {
        role: "af"
      },
      fetchPolicy: 'network-only',
      update: data => (data.nouveautes) ? data.nouveautes.slice(0,4) : [],
      result({ data, loader, networkStatus }) {}
    },
    statsCaJG_Lead: { 
      query: gql`query ($id_client: Int!) {
        statsCaJG_Lead (droit: "af",id_client: $id_client) {
          ca
          marge
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      skip() {
        return this.id_client === 0
      },
      update: data => data.statsCaJG_Lead
    },
    statsCaJG_Click: {
      query: gql`query ($id_client: Int!) {
        statsCaJG_Click (droit: "af",id_client: $id_client) {
          ca
          marge
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      skip() {
        return this.id_client === 0
      },
    },
    statsCaWeek: { 
      query: gql`query ($id_client: Int!) {
        statsCaWeek_Click (droit: "af",id_client: $id_client) {
          ca
        },
        statsCaWeek_Lead (droit: "af",id_client: $id_client) {
          ca
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      update: data => data,
      result({ data, loader, networkStatus }) {
        for (let i = 0; i <  this.statsCaWeek.statsCaWeek_Lead.length; i++) {
          if(this.statsCaWeek.statsCaWeek_Lead[i].ca == null && this.statsCaWeek.statsCaWeek_Click[i].ca == null)
            bigChartData[0][i] = null
          else
            bigChartData[0][i] = (this.statsCaWeek.statsCaWeek_Lead[i].ca + this.statsCaWeek.statsCaWeek_Click[i].ca).toFixed(2)
        }
        bigChartLabels = this.setChartLabels()
        this.initBigChart(0);
      },
      error(error, vm, key, type, options) {
        bigChartLabels = this.setChartLabels()
        this.initBigChart(0);
        bigChartData[0][0] = 0
      }
    },
    statShootOfWeek: { 
      query: gql` query ($id_client: Int!){
        statShootOfWeek (droit:"af", id_client: $id_client){
          day
          shoots
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      result({ data, loader, networkStatus }) {
        this.statShootOfWeek.map(function(value, key) {
          bigChartData[1][key] = value.shoots
        });
      },
      error(error, vm, key, type, options) {
        bigChartData[1][0] = 0
      }
    },
    statLeadsOfWeek: {
      query: gql` query ($id_client: Int!){
        statLeadsOfWeek (droit:"af", id_client: $id_client){
          day
          leads
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      result({ data, loader, networkStatus }) {
        this.statLeadsOfWeek.map(function(value, key) {
          bigChartData[2][key] = (value.leads == 0) ? null : value.leads
        });
      },
      error(error, vm, key, type, options) {
        bigChartData[2][0] = 0
      }
    },
  },
  components: {
    LineChart,
    BarChart,
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Carousel3d,
    Slide,
    VedioPresentation,
    // PopupWeedoMax
  },
  data() {
    return {
      ListThematique: this.$t("Database.Thematiques"),
      Mounth: this.$t('labels.Mounths'),
      Offers_af: Offers,
      Offers_Speciale: Offers_Speciale,
      societe: JSON.parse(localStorage.getItem('utilisateur')).society,
      id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil,
      statsCaJG_Click: null,
      statsCaWeek: null,
      statShootOfWeek: null,
      statLeadsOfWeek: null,
      statsCaJG_Lead: '',
      skipQuery: true,
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartData[0]
          }],
          labels: bigChartLabels
        },
        extraOptions: {
          ...chartConfigs.purpleChartOptions,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartData[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else if(data.datasets[0].data == bigChartData[1])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " shoots"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 10,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      purpleLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      greenLineChart: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels:  ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'My First dataset',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0]
            }
          ]
        },
        gradientColors: [
          'rgba(66,134,121,0.15)',
          'rgba(66,134,121,0.0)',
          'rgba(66,134,121,0)'
        ],
        gradientStops: [1, 0.4, 0]
      },
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
          datasets: [
            {
              label: 'Countries',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [0, 0, 0, 0, 0, 0]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [
        { name: 'CA', icon: 'tim-icons icon-coins' }, 
        { name: 'SHOOTS', icon: 'tim-icons icon-spaceship' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    },
    bigLineChartCategories2() {
      return [
        { name: 'CA', icon: 'tim-icons icon-coins' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    }
  },
  methods: {
    setChartLabels() {
      let curr = new Date
      var yesterday = new Date(curr.getTime());
      yesterday.setDate(curr.getDate() - 1);
      let week = []
      for (let i = 1; i <= 7; i++) {
        let first = yesterday.getDate() - yesterday.getDay() + i 
        let day = new Date(yesterday.setDate(first)).toISOString().slice(0, 10).split("-")
        week.push(day[2]+"/"+ this.Mounth[parseInt(day[1]-1)])
      }
      return week
    },
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      // this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    Visualiser(row) {
      localStorage.setItem('ProgrammeVisualiser', JSON.stringify(row))
      this.$router.push({name: "Visualiser programme My Affil Editeur"})
    },
    imageLoadError(event) {
      event.target.src =
        "/img/missingprofile.jpg";
    },
  },
  mounted() {
    if (this.$hj) {
      var userId = null;
      this.$hj('identify', userId, {
        societe: this.societe
      })
    }
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  }
};
</script>
<style scoped>
  .prog {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .prog img {
    width: 100%;
  }
</style>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  video {
    outline: 0px;
  }
</style>